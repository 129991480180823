@import 'src/scss/utilities/breakpoints';
@import 'src/scss/utilities/mixins';
@import 'src/scss/variables';

.section {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    @include md-up {
        flex-direction: row;
        gap: 4rem;
        > * {
            flex: 1;
        }
    }
    h1 {
        color: $green-dark;
    }
    .hero_formula {
        position: relative;
        height: 24rem;
        margin-bottom: 10rem;
        @include sm-up {
            margin-bottom: 2rem;
        }
        @include md-up {
            height: 460px;
            align-self: center;
        }
        .slide {
            transition: opacity 0.3s ease-in-out;
            visibility: hidden;
            opacity: 0;
            height: 0;
            &[data-active='true'] {
                visibility: visible;
                opacity: 1;
                height: 100%;
            }
        }
        &-hero {
            object-fit: cover;
            // 40rem is larger than the image will ever be
            // but small enough to allow the right borders
            border-top-left-radius: 40rem;
            border-bottom-left-radius: 40rem;
            border-top-right-radius: 2rem;
            border-bottom-right-radius: 2rem;
            padding-left: 2rem;
        }
        &-wrapper {
            position: absolute;
            bottom: -10rem;
            @include sm-up {
                bottom: -2rem;
            }
        }
        &-pager {
            margin: 6px 0.75rem;
        }
    }
}
.content {
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 1rem;
    .description {
        @apply text-regular text-green-dark;
        @include lg-up {
            font-size: $font-medium;
        }
        ul {
            li {
                @apply text-regular;
                svg {
                    @apply pr-4 w-6 h-6;
                    @include between-zero-md {
                        @apply w-4 h-4;
                    }
                }
            }
        }
    }
    .btn_wrapper {
        &--default {
            @apply w-full text-center;
            @apply md:px-0 md:py-0 md:border-none md:text-left md:w-fit;
            transition: transform 200ms ease-in-out;
            @include between-zero-md {
                transform: translateY(100%);
                margin-top: -3rem;
                margin-bottom: 3rem;
            }
        }
        &--intersected {
            @include apply-sticky-bottom;
            @apply fixed bottom-0 left-0 z-50 w-full px-4 bg-white;
            @apply md:relative md:border-none md:p-0 md:border-0 md:shadow-none md:w-fit;
            transform: translateY(0);
            animation: slideUp;
            transition: transform 200ms ease-in-out;
            padding-bottom: calc(env(safe-area-inset-bottom) + 0.75rem);
        }
    }
}

@keyframes slideUp {
    0%,
    10% {
        transform: translateY(100%);
        opacity: 0;
        transition: transform 200ms ease-in-out;
    }

    60%,
    100% {
        transform: translateY(0);
        opacity: 1;
        transition: transform 200ms ease-in-out;
    }
}
