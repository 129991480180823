@import 'src/scss/utilities/breakpoints';
@import 'src/scss/variables';

.grid {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
    @include md-up {
        @apply container;
    }
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 2rem 0;
    flex: 1;
    @include md-up {
        gap: 1rem;
    }
    .info {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        text-align: center;
        max-width: 6rem;
        @include md-up {
            max-width: 14rem;
        }
        @include lg-up {
            max-width: 24.5rem;
        }
    }
    img {
        color: $grey-300;
    }
}
