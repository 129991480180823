@import 'src/scss/utilities/breakpoints';
@import 'src/scss/variables';

.comparison-cards {
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
    padding: 1rem;
    :global(.swiper-pagination) {
        position: static;
        display: flex;
        width: 100%;
        margin: 1rem 0rem;
        background-color: $blue-extra-light;
        @include md-up {
            display: none;
        }
        border-radius: 6rem;
        :global(.swiper-pagination-bullet) {
            display: flex;
            padding: 0.5625rem;
            align-items: center;
            justify-content: center;
            width: 33.3333%;
            height: auto;
            margin: 0;
            background-color: transparent;
            border-radius: 6rem;
            font-family: $font-maisonneue-demi;
            opacity: 1;
            color: $grey-400;
        }
        :global(.swiper-pagination-bullet-active) {
            background-color: $blue-light;
            // hack to make the active bullet bigger
            margin: -0.25rem 0rem;
            color: $black;
        }
    }
    :global(.swiper-wrapper) {
        @apply items-center;
    }
}

.comparison-cards--card {
    height: calc(var(--card-height) - 2rem);
    border: 1px solid $grey-200;
    border-radius: 1rem;
    &[data-card-featured='true'] {
        height: auto;
        background-color: $blue-extra-light;
    }
    box-shadow: $box-shadow-md;
    &--header {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
    &--heading {
        color: $green-dark;
        > p {
            font-size: 1.125rem;
            font-family: $font-maisonneue-demi;
        }
    }
    &--includes {
        font-size: $font-xsmall;
        color: $grey-400;
        [data-card-featured='true'] & {
            color: $black;
        }
    }
    &--price {
        color: $grey-400;
        font-family: $font-maisonneue-demi;
        [data-card-featured='true'] & {
            color: $green-dark;
        }
    }
    &--content {
        border-top: 1px solid $grey-200;
        padding: 1rem;
    }
}
