@import 'src/scss/utilities/breakpoints';
@import 'src/scss/variables';

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    h2 {
        text-align: center;
    }
    @include md-up {
        flex-direction: row;
        gap: 1.5rem;
        h2 {
            text-align: left;
        }
        > * {
            flex: 1;
        }
    }

    .content-container {
        position: relative;
        height: 29rem;
        @include sm-up {
            height: 27.5rem;
        }
        width: 100%;
        .slide {
            transition: opacity 0.3s ease-in-out;
            visibility: hidden;
            opacity: 0;
            height: 0;
            &[data-active='true'] {
                visibility: visible;
                opacity: 1;
                height: 100%;
            }
            .image-container {
                position: relative;
                height: calc(100% - 10rem);
                width: calc(100% - 2rem);
                left: 2rem;
                @include sm-up {
                    height: calc(100% - 4rem);
                    width: calc(100% - 4rem);
                    left: 4rem;
                }
                img {
                    object-fit: cover;
                    overflow: hidden;
                    border-radius: 1rem;
                }
            }
        }
        .formula {
            position: absolute;
            bottom: 0;
            left: 0;
        }
        .pager {
            padding: 6px 1rem;
            // Formula card width
            width: 241px;
        }
    }

    .call-to-action {
        display: none;
        @include md-up {
            margin-top: 1.5rem;
            display: inline-block;
        }
    }
}
