@import 'src/scss/utilities/breakpoints';
@import 'src/scss/variables';
@import 'src/scss/variables/components';

.wrapper {
    background-color: $white;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    width: 241px;
    border-radius: 1rem;
    border: 1px solid $grey-200;
    padding: 1rem 0;
    background-color: $white;
    color: $black;
    box-shadow: $box-shadow-md;
    h4 {
        font-family: $font-family-main;
        padding: 0 1rem;
    }
    .targeting {
        padding: 0 1rem;
        font-family: $font-maisonneue-demi;
        color: $grey-400;
    }
    ul {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 0 1rem;
        li {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;
            svg {
                border-radius: 1px;
                background-color: $blue-extra-light;
                padding: 5px;
            }
        }
    }
    .container {
        display: flex;
        flex-direction: column;
        padding: 0 1rem;
        color: $purple-dark;
        .subtitle {
            font-family: $font-maisonneue-bold;
            margin-bottom: 0.5rem;
        }
        span {
            font-size: $font-large;
            font-family: $font-family-highlight;
            line-height: normal;
        }
        small {
            color: $grey-400;
        }
    }
}
