@import 'src/scss/variables';

.snake-pager {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    &__item {
        width: 100%;
        height: 0.25rem;
        border-radius: 2px;
        background: linear-gradient(
                to left,
                $grey-300 33%,
                $blue-light 33%,
                $blue-light 66%,
                $grey-300 66%
            )
            right;
        background-size: 300% 100%;
        transition: none;
        &.active {
            transition: background-position 2s linear;
            background-position: left;
        }
    }
}
