@import 'src/scss/variables';
@import 'src/scss/utilities/breakpoints';

.faq-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    > * {
        max-width: 44rem;
    }
    gap: 1.5rem;
    @include md-up {
        gap: 3rem;
    }
}
